<script>
import {list, rotinasAutomaticas} from "@/domain/gerencia/clientes/services"
import {UTable, UTh, UTr, UTd, UCheckbox, date, LocalStorage, UTooltip, UProgress} from 'uloc-vue'
import MenuOptions from '@/components/layout/context-menu/context-window-options'
import {ErpInput, /*ErpSelect,*/ ErpSField} from 'uloc-vue-plugin-erp'
//import DefaultMixin from '../mixin'
import StatusSelect from "components/gerencia/cliente/components/include/StatusClienteSelect"
import {datePtToEn} from '@/utils/date'
import ECol from "components/layout/components/Col.vue"
import EBtn from "@/plugins/uloc-erp/components/button/EBtn"
import ERow from "components/layout/components/Row.vue"
import FiltrosAdicionais from "components/console/components/clientes/include/ListFilters.vue"
import listOptions from "components/usuario/v2/window/listOptions"
import filters from "@/domain/gerencia/clientes/helpers/filtersCliente"
import {addDays, differenceInHours, endOfWeek, format, parseISO, startOfWeek, subDays} from "date-fns";
import {listName, listStorage} from "@/components/console/components/clientes/config/list"
import CheckSvg from "@/assets/images/CheckSvg.vue"
import AlertSvg from "@/assets/images/AlertSvg.vue"
import StatusCliente from "components/clientes/components/cliente/include/StatusCliente.vue";
import SatisfacaoRating from "components/clientes/components/cliente/include/SatisfacaoRating.vue";
import clienteWindow from "components/clientes/components/cliente/window/cliente"

export default {
  name: 'ConsoleClientesList',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [
    //DefaultMixin
  ],
  components: {
    SatisfacaoRating, StatusCliente,
    //AlertSvg,
    //CheckSvg,
    ERow,
    ECol,
    // DateInput,
    StatusSelect,
    // SearchFilter,
    UTable,
    UTh,
    UTr,
    UTd,
    //UCheckbox,
    ErpInput,
    EBtn,
    // ErpSelect,
    ErpSField,
    FiltrosAdicionais,
    //UTooltip,
  },
  beforeCreate() {
    this.listStorage = listStorage
  },
  data () {
    return {
      listName: listName,
      listStorage: this.listStorage,
      filtersData: {
        uf: {
          loading: true,
          serverData: []
        }
      },
      showFilters: false,
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 0, // specifying this determines pagination is server-side
          rowsPerPage: 100
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      },
      stats: {
        cadastradosHoje: 0,
        cadastradosSemana: 0,
        paraAnalisar: 0
      },
      statsFilterActive: null,
      rotinasAutomaticasLoading: false
    }
  },
  computed: {
    visibledColumns() {
      return this.listStorage.filter(item => {
        let r = item.active
        if (this.$route.query.modoFinanceiro) {
          if (['domain', 'plano', 'ultimoPagamento', 'proximoVencimento', 'mensalidade', 'pagamento', 'divida', 'saldo', 'status'].includes(item.name)) {
            return true
          } else {
            return false
          }
        }
        return r
      }).map(item => item.name)
    },
    menuDashOptions () {
      return MenuOptions
    }
  },
  mounted () {
    this.rotinasAutomaticas(false)
    // this.load()
  },
  destroyed () {
  },
  methods: {
    listOptions: listOptions,
    updateListDefinition(newConfig) {
      LocalStorage.set(this.listName, newConfig)
      this.listStorage = newConfig
    },
    isOpen (task) {
      return task.status.type === 'opened'
    },
    atraso (d) {
      return Math.ceil(differenceInHours(parseISO(d.date || d), new Date()) / 24) * -1
    },
    abrir(t) {
      this.$router.push({
        name: 'console.clientes.cliente',
        params: {
          id: t.id
        }
      })
    },
    trClass (task) {
      const css = []
      if (this.colorize) {
        if (task.deadline && this.atraso(task.deadline) > 0) {
          css.push('sla-out')
        }
      }
      return css
    },
    request({pagination, filter}) {

      // this.table.serverPagination.rowsNumber = 0

      let data1, data2
      let extraFilters = []

      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10 || (!this.table.filters.data2 || this.table.filters.data2.length < 10)) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }
      this.table.filters.tipoData && extraFilters.push(`&typeDate=${this.table.filters.tipoData}`)

      this.table.filters.search && extraFilters.push(`&search=${String(this.table.filters.search).replace('#', '_hash-')}`)
      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.active && extraFilters.push(`&active=${this.table.filters.active}`)
      this.table.filters.gerente && extraFilters.push(`&gerente=${this.table.filters.gerente.id}`)
      typeof this.table.filters.contratoAssinado !== 'undefined' && this.table.filters.contratoAssinado !== null && extraFilters.push(`&contratoAssinado=${this.table.filters.contratoAssinado ? 1 : 0}`)
      this.table.filters.status.length && extraFilters.push(`&status=${this.table.filters.status.join(',')}`)

      this.table.loading = true
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'dominio'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : false}${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros, this.isSuporte)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    load () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    clearFilters () {
      this.table.filters = JSON.parse(JSON.stringify(filters))
    },
    novo () {
      console.log('New...')
    },
    pesquisar () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.search
      })
    },
    tabFilter (t) {
      if (this.table.filters.typeFilter === t) {
        this.table.filters = JSON.parse(JSON.stringify(filters))
        this.load()
        return
      }
      this.table.filters = JSON.parse(JSON.stringify(filters))
      this.table.filters.typeFilter = t
      this.load()
    },
    clienteWindow,
    criarCliente () {
      this.clienteWindow(null, null)
    },
    edit (id) {
      this.clienteWindow(id)
    },
    isAtrasado (c) {
      if (!c.mensalidadeProximoVencimento) {
        return false
      }
      console.log(c.dominio, Math.ceil(differenceInHours(parseISO(c.mensalidadeProximoVencimento.date || c.mensalidadeProximoVencimento), new Date()) / 24) * -1)
      return (Math.ceil(differenceInHours(parseISO(c.mensalidadeProximoVencimento.date || c.mensalidadeProximoVencimento), new Date()) / 24) * -1) > 0
    },
    rotinasAutomaticas (alert = true) {
      this.rotinasAutomaticasLoading = true
      rotinasAutomaticas()
          .then(response => {
            alert && this.dg('Operação realizada com sucesso!')
            this.rotinasAutomaticasLoading = false
            this.load()
          })
          .catch(error => {
            this.rotinasAutomaticasLoading = false
            this.alertApiError(error)
          })
    }
  },
  meta: {
    title: 'Suporte SL - Fila',
    name: 'Suporte'
  }
}
</script>

<template>
  <div v-if="$route.name === 'console.clientes.lista'" class="erp-list-task console-pad">
    <div class="flex justify-between">
      <div class="console-task-opts">
        <div class="cleft"></div>
        <div class="cright">
          <u-btn @click="() => rotinasAutomaticas(true)" :loading="rotinasAutomaticasLoading" no-caps color="grey" class="tbtn m-r-sm">
            <i class="fa-duotone fa-robot m-r-sm" /> Executar rotinas automáticas
          </u-btn>
          <u-btn @click="criarCliente" no-caps color="green" class="tbtn">
            <i class="fa-duotone fa-user-plus m-r-sm" /> Novo Cliente
          </u-btn>
        </div>
      </div>
      <!--      <div class="flex justify-end m-t m-b-lg">
              <u-btn @click="novo" class="sl-btn" color="green" no-caps v-shortkey="['shift', 'n']"
                     @shortkey.native="novo">Cadastrar <span class="shortcut font-10 m-l">Shift + N</span></u-btn>
            </div>-->
    </div>
    <u-table
        ref="table"
        color="primary"
        :data="table.serverData"
        :columns="table.columns"
        :visible-columns="visibledColumns"
        :filter="table.busca"
        row-key="id"
        :pagination.sync="table.serverPagination"
        @request="request"
        selection="multiple"
        :selected.sync="table.selected"
        :loading="table.loading"
        class="erp-table table-v2 table-shadow"
        :rows-per-page-options="[20, 50, 100]"
    >
      <template slot="top" slot-scope="props">
        <div class="sl-v2-table-filters full-width">
          <e-row class="e-input-modern size1">
            <e-col style="min-width: 150px" class="m-l m-r">
              <erp-s-field
                  view="ll"
                  label="Busca"
              >
                <erp-input placeholder="Busca Inteligente" v-on:keyup.enter="pesquisar" shortkey="F2" v-model="table.filters.search" />
              </erp-s-field>
            </e-col>
            <e-col style="max-width: 100px" class="m-l m-r">
              <erp-s-field
                  view="ll"
                  label="ID"
              >
                <erp-input v-on:keyup.enter="pesquisar" shortkey="F3" v-model="table.filters.id" />
              </erp-s-field>
            </e-col>
            <e-col style="min-width: 150px" class="m-l m-r">
              <status-select size="2" label="Status:" placeholder="Selecione" field-view="lr" simple-border=""
                             v-model="table.filters.status" multiple/>
            </e-col>
            <e-col style="max-width: 80px">
              <e-btn @click="pesquisar" label="Filtrar" />
              <!--                  <u-btn @click="pesquisar" color="grey" size="sm" class="app-btn erp-btn text-white" no-caps>Filtrar</u-btn>-->
            </e-col>
            <e-col class="flex items-end content-end justify-end">
              <u-btn @click="showFilters = !showFilters" color="blue-grey-1" size="sm" class="app-btn text-blue-grey-5" no-caps>
                <!--    Mais filtros-->
                <u-icon name="filter" class="m-l__ m-r-sm fin-icon-filter" type="fa"/>
                <span v-if="!showFilters">Mais Filtros</span>
                <span v-else>Menos Filtros</span>
              </u-btn>
            </e-col>
          </e-row>
          <e-row>
            <filtros-adicionais :filters="table.filters" v-show="showFilters" />
          </e-row>
        </div>
      </template>
      <u-tr slot="header" slot-scope="props">
        <!--        <u-th auto-width v-if="mode !== 'cliente'">
                  <u-checkbox
                      v-model="props.selected"
                      :indeterminate="props.partialSelected"
                      size="xs"
                  />
                </u-th>-->
        <u-th v-for="col in props.cols" :key="col.name" :props="props" :class="'col-' + col.name">
          <div v-if="col.name === 'options'" class="text-center">
            <u-btn style="margin: -10px 0 -6px" @click="listOptions" label="Configurar lista" rounded round flat no-caps size="xs" icon="cog" icon-type="fa" color="grey-4" class="text-grey-8"/>
          </div>
          <template v-else>
            <span v-if="col.name === 'nome'">{{col.label}} <small class="text-blue-grey">({{table.serverPagination.rowsNumber}})</small></span>
            <span v-else>{{col.label}}</span>
          </template>
        </u-th>
      </u-tr>

      <u-tr slot="body" slot-scope="props"
            :props="props" :class="[{'u-table-item-destac': props.row.isToday}, trClass(props.row)]">
        <!--        <u-td class="pos-rlt" auto-width v-if="mode !== 'cliente'">
                  <u-checkbox color="primary" v-model="props.selected" size="xs"/>
                </u-td>-->
        <u-td style="max-width: 50px; min-width: 50px; width: 50px" key="id" :props="props">
          {{ props.row.id }}
        </u-td>
        <u-td key="nome" :props="props">
          <router-link :to="{name: 'console.clientes.cliente', params: {id: props.row.id}}" tag="a">{{ props.row.pessoa.name }}</router-link>
        </u-td>
        <u-td key="domain" :props="props">
          {{ props.row.dominio }}
        </u-td>
        <u-td key="obs" :props="props" style="max-width: 200px;">
          <div style="word-wrap: break-word; overflow-wrap: break-word; white-space: normal; max-width: 200px; min-width: 100px; display: block">{{ props.row.observacao }}</div>
        </u-td>
        <u-td key="rate" :props="props">
          <satisfacao-rating v-if="!table.loading" :cliente="props.row" size="xs" />
        </u-td>
        <u-td key="plano" :props="props">
          {{ props.row.plano.nome }}
        </u-td>
        <u-td class="td-limit today" key="contrato" :props="props">
            <span v-if="!props.row.contratoAssinado" class="m-l-xs trask-atraso-lbl">
              <i class="fas fa-exclamation-triangle m-r-xs"></i> Não assinado
            </span> <span v-if="props.row.contratoInicio">{{props.row.contratoInicio|formatDate('dd/MM/yyyy')}}</span>
        </u-td>
        <u-td key="ticketIn" :props="props">
          {{ props.row.ticketIn|moeda }}
        </u-td>
        <u-td key="ticketOut" :props="props">
          {{ props.row.ticketOut|moeda }}
        </u-td>
        <u-td key="bens" :props="props">
          {{ props.row.statBens|number }}
        </u-td>
        <u-td key="leiloes" :props="props">
          {{ props.row.statLeiloes|number }}
        </u-td>
        <u-td key="vendas" :props="props">
          {{ props.row.statVendas|moeda }}
        </u-td>
        <u-td key="ultiomoPagamento" :props="props">
          {{ props.row.mensalidadeUltimoPagamento|formatDate('dd/MM/yyyy') }}
        </u-td>
        <u-td key="proximoVencimento" :props="props">
          {{ props.row.mensalidadeProximoVencimento|formatDate('dd/MM/yyyy') }}
        </u-td>
        <u-td key="mensalidade" :props="props">
          {{ props.row.mensalidade|moeda }}
        </u-td>
        <u-td class="text-uppercase" key="status" :props="props">
          <div :class="'cliente-status-' + props.row.status">
            <status-cliente :cliente="props.row" />
          </div>
        </u-td>
        <u-td key="pagamento" :props="props">
          <span v-if="props.row.pagamentosPendentes > 0" class="m-l-xs trask-atraso-lbl">
              <i class="fas fa-exclamation-triangle m-r-xs"></i> Atrasado
          </span>
          <span v-else-if="!props.row.mensalidadeProximoVencimento" class="m-l-xs trask-atraso-lbl bg-white text-purple" style="opacity: 0.5">
            Nunca
          </span>
<!--          <span v-else-if="props.row.pagamentosPendentes > 0" class="m-l-xs trask-atraso-lbl bg-orange">
            Pendente
          </span>-->
          <span v-else class="m-l-xs trask-atraso-lbl bg-white text-positive" style="opacity: 0.5">
            Em dia
          </span>
        </u-td>
        <u-td key="divida" :props="props">
          {{ props.row.pagamentosPendentes|moeda }}
        </u-td>
        <u-td key="saldo" :props="props">
          {{ props.row.saldo|moeda }}
        </u-td>
        <u-td class="text-center" key="options" style="width: 100px; max-width: none" :props="props">
          <div style="width: 100px">
            <a @click="edit(props.row.id)" class="text-blue-grey-3"><i class="fa fa-user-edit"/></a>
            <router-link tag="a" :to="{name: 'console.clientes.cliente', params: {id: props.row.id}}" target="_blank" class="text-blue-grey-3 m-l-sm"><i class="fa fa-eye"/></router-link>
          </div>
        </u-td>
      </u-tr>
    </u-table>
  </div>
  <router-view v-else />
</template>
